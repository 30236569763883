import React from "react";

function Publicacoes() {
  return (
    <section className="seccao2" id="publicacoes">
      <h1>Publicações</h1>
      <div className="texto">
        <h2>Artigos</h2>
        <div>
          <ul className="justify">
            <li>
              R. Oliveira et al., Development of a new integrated system for
              vital sign monitoring in small animals, Sensors, 22(11), 4264
              (2022) doi:{" "}
              <a href="https://www.mdpi.com/1424-8220/22/11/4264">
                10.3390/s22114264
              </a>
            </li>
            <li>
              I. Mohammadi et al., Motion in nuclear cardiology imaging: types,
              artifacts, detection and correction techniques, Physics in
              Medicine & Biology, 67, 02TR02 (2022) doi:{" "}
              <a href="https://doi.org/10.1088/1361-6560/ac3dc7">
                10.1088/1361-6560/ac3dc7
              </a>
            </li>
            <li>
              C. Nicolucci et al., Single Low Dose of Cocaine – Structural Brain
              Injury Without Metabolic and Behavioral Changes, Frontiers in
              Neuroscience, 14:589897 (2021) doi:{" "}
              <a href="https://www.frontiersin.org/articles/10.3389/fnins.2020.589897/full">
                10.3389/fnins.2020.589897
              </a>
            </li>
            <li>
              1 abstract (OP-0772): European Association of Nuclear Medicine
              October 20-23, 2021, Virtual, European Journal of Nuclear Medicine
              and Molecular Imaging, 48, 1-648 (2021) doi:
              <a href="https://doi.org/10.1007/s00259-021-05547-1">
                10.1007/s00259-021-05547-1
              </a>
            </li>
            <li>
              5 abstracts (OP-236, OP-842, EPS-089, EP-266, EP-294): European
              Association of Nuclear Medicine October 22-30, 2020, Virtual,
              European Journal of Nuclear Medicine and Molecular Imaging, 47,
              1-753 (2020) doi:{" "}
              <a href="https://doi.org/10.1007/s00259-020-04988-4">
                10.1007/s00259-020-04988-4
              </a>
            </li>
          </ul>

          <h2>Publicações</h2>
          <ul className="justify">
            <li>
              R. Oliveira et al., Integrated vital signs monitoring system for
              in-vivo preclinical imaging (
              <a href="https://drive.google.com/file/d/1tTNcPo_mZbWDg68OQktsi5aoMdaZBCp9/view?usp=sharing">
                apresentação oral
              </a>
              ), IUPESM World Congress on Medical Physics and Biomedical
              Engineering (IUPESM WC 2022), Hybrid, Singapore, 12-17 Jun. 2022
            </li>
            <li>
              P. Correia et al., easyPET.3D: a benchtop affordable PET scanner
              towards democratization of nuclear medical imaging education and
              preclinical research (
              <a href="https://drive.google.com/file/d/1HVCY7PtPxd7ljIz-tzyB7mGHPcjYYvkC/view?usp=sharing">
                poster
              </a>
              ), IUPESM World Congress on Medical Physics and Biomedical
              Engineering (IUPESM WC 2022), Hybrid, Singapore, 12-17 Jun. 2022
            </li>
            <li>
              A.L. Silva et al., A simple CT integration in a preclinical PET
              scanner (poster), IUPESM World Congress on Medical Physics and
              Biomedical Engineering (IUPESM WC 2022), Hybrid, Singapore, 12-17
              Jun. 2022
            </li>
            <li>
              F. Ribeiro et al., Mouse handling for 18F-FDG PET imaging:
              preparing the way for a future guideline (
              <a href="https://drive.google.com/file/d/13Y0rbzTouFMUnRX6O-VDTBUxfISmKRFT/view?usp=sharing">
                apresentação oral
              </a>
              ), Annual Congress of the European Association of Nuclear Medicine
              (EANM 2021), Virtual, 20-23 Oct. 2021
            </li>
            <li>
              P. Encarnação et al., New geometric projector with DOI estimator
              for GPU based iterative imaging algorithms (
              <a href="https://drive.google.com/file/d/13Y0rbzTouFMUnRX6O-VDTBUxfISmKRFT/view?usp=sharing">
                poster
              </a>
              ), 2021 IEEE Nuclear Science Symposium and Medical Imaging
              Conference (NSS/MIC), Virtual, 16-23 Oct. 2021
            </li>
            <li>
              P. Correia et al., Preclinical PET scanners based in easyPET
              technology: new developments for full body mice imaging (
              <a href="https://drive.google.com/file/d/1u43K_rn35rk4Mz2vnxOidm1Te7XJDzSn/view">
                poster
              </a>
              ), 2020 IEEE Nuclear Science Symposium and Medical Imaging
              Conference (NSS/MIC), Virtual, 31 Oct. - 7 Nov. 2020
            </li>
            <li>
              F. Ribeiro et al., A rat preliminary study on medication-related
              osteonecrosis of the jaw with a high-resolution microPET system (
              <a href="https://drive.google.com/file/d/1bQKj5J68VIqBbms7pnj6HE4tFXt7tdxI/view">
                poster
              </a>
              ), 2020 IEEE Nuclear Science Symposium and Medical Imaging
              Conference (NSS/MIC), Virtual, 31 Oct. - 7 Nov. 2020
            </li>
            <li>
              F. Ribeiro et al., A preclinical drug experiment in a mouse model
              using easyPET.3D (
              <a href="https://drive.google.com/file/d/12l56PtfkSGiVpqN1TJ7df4ZedJNOY9Pl/view">
                poster
              </a>
              ), 2020 IEEE Nuclear Science Symposium and Medical Imaging
              Conference (NSS/MIC), Virtual, 31 Oct. - 7 Nov. 2020
            </li>
            <li>
              P. Encarnação et al., A reconstruction method based on LM-MLEM for
              an Ultra-dense VoR PET (
              <a href="https://drive.google.com/file/d/1CGMczRQUgDvowxfw6VRnHJL8roW433OF/view">
                poster
              </a>
              ), 2020 IEEE Nuclear Science Symposium and Medical Imaging
              Conference (NSS/MIC), Virtual, 31 Oct. - 7 Nov. 2020
            </li>
            <li>
              I.F. Castro et al., Characteristics and properties of a novel
              cost-effective preclinical PET scanner for high performance
              molecular imaging of small animal models (
              <a href="https://drive.google.com/file/d/1yIodRqSV1-ZiZ6I76zox9i4EBwQ8mZiB/view">
                apresentação oral
              </a>
              ), Annual Congress of the European Association of Nuclear Medicine
              (EANM 2020), Virtual, 23 Oct. 2020
            </li>
            <li>
              P. Encarnação et al., LM-MLEM for an Ultra-High VoR density PET (
              <a href="https://drive.google.com/file/d/1p912R8GioJAoRgFyWc0r6OOzSl3Axxdv/view">
                apresentação oral
              </a>
              ), Annual Congress of the European Association of Nuclear Medicine
              (EANM 2020), Virtual, 30 Oct. 2020
            </li>
            <li>
              I. Mohammadi et al., Design and Implementation of a network-based
              educational platform for simulation of a benchtop PET system
              (poster com apresentação oral), Annual Congress of the European
              Association of Nuclear Medicine (EANM 2020), Virtual, 24 Oct. 2020
            </li>
            <li>
              F. Ribeiro et al., Quercetin as a potential psychotherapeutic
              agent in a mouse model with acute exposure to cocaine: a
              preclinical PET study (poster), Annual Congress of the European
              Association of Nuclear Medicine (EANM 2020), Virtual, 22-30 Oct.
              2020
            </li>
            <li>
              F. Ribeiro et al., A rat preliminary study on medication-related
              osteonecrosis of the jaw (poster), Annual Congress of the European
              Association of Nuclear Medicine (EANM 2020), Virtual, 22-30 Oct.
              2020
            </li>
            <li>
              P. Correia et al., EasyPET technologies (apresentação oral), 22nd
              IEEE Real Time Conference, Virtual, 19 Oct. 2020
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Publicacoes;
