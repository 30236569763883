import "./App.css";
import Navigation from "./Components/Navigation";
import Header from "./Components/Header";
import Descricao from "./Components/Table";
import Sintese from "./Components/Sintese";
import Resultados from "./Components/Resultados";
import Eventos from "./Components/Eventos";
import Publicacoes from "./Components/Publicacoes";
import Footer from "./Components/Footer";
import Consorcio from "./Components/consorcio";
import PT2020 from "./Components/LogoPT2020";

function App() {
  return (
    <>
      <Navigation />
      <Header />
      <PT2020/>
      <Descricao />
      <Consorcio/>
      <Sintese />
      <Resultados />
      <Eventos />
      <Publicacoes />
      <Footer />
    </>
  );
}

export default App;
