import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import logo from "../Assets/logo.png";
import "../Styles/navigation.css";
import Nav from "react-bootstrap/Nav";

function Navigation() {
  //   const refreshPage = () => {
  //     window.location.reload();
  //   };

  return (
    <>
      <header class="header">
        <a href="#home" className="logo">
          <img
            src={logo}
            className="logoipet d-inline-block align-top"
            alt=""
          />
        </a>
        <input class="menu-btn" type="checkbox" id="menu-btn" />
        <label class="menu-icon" for="menu-btn">
          <span class="navicon"></span>
        </label>
        <ul class="menu">
          <li>
            <a href="#descricao">Descrição</a>
          </li>
          <li>
            <a href="#consorcio">Consórcio</a>
          </li>
          <li>
            <a href="#sintese">Síntese</a>
          </li>
          <li>
            <a href="#resultados">Resultados</a>
          </li>
          <li>
            <a href="#eventos">Eventos</a>
          </li>
          <li>
            <a href="#publicacoes">Publicações</a>
          </li>
        </ul>
      </header>
    </>
  );
}

export default Navigation;
