import React from "react";
import iupesm from "../Assets/IUPESM.png";
import snmmi from "../Assets/snmmi.png";
import virtual from "../Assets/SNMMI_virtual.png";
import stand from "../Assets/SNMMI_stand.png";
import eanm from "../Assets/EANM.png";
import eanm1 from "../Assets/EANM1.png";
import eanm2 from "../Assets/EANM2.png";
import eanm3 from "../Assets/EANM3.png";
import eanm4 from "../Assets/EANM4.png";
import rg from "../Assets/researchgate.png";
import { Carousel } from "nuka-carousel/es-v5/carousel";

function Eventos() {
  return (
    <section className="seccao1" id="eventos">
      <h1>Eventos, promoção e divulgação de resultados</h1>
      <div className="texto2">
        <h2>
          Congresso mundial de Física Médica e Engenharia Biomédica da IUPESM,
          2022
        </h2>
        <p>
          O Congresso Mundial de Física Médica e Engenharia Biomédica da IUPESM
          (International Union for Physical and Engineering Sciences in
          Medicine) (WC 2022), realizou-se de 12 a 17 de junho de 2022 em
          formato híbrido, online e em Singapura. Foram apresentados três
          trabalhos resultantes dos trabalhos de I&D do consórcio do projeto
          iPET, por elementos da Universidade de Aveiro:
        </p>
        <ul>
          <li>
            Integrated vital signs monitoring system for in-vivo preclinical
            imaging;
          </li>
          <li>A simple CT integration in a preclinical PET scanner;</li>
          <li>
            easyPET.3D: a benchtop affordable PET scanner towards
            democratization of nuclear medical imaging education and preclinical
            research.
          </li>
        </ul>
        <img className="img1" src={iupesm} alt="" />

        <h2 className="ptop">Encontro anual SNMMI 2020</h2>
        <p>
          O projeto iPET foi apresentado no encontro anual da Associação
          Americana de Medicina Nuclear e Imagiologia Molecular (SNMMI 2020), de
          11 a 14 de julho de 2020 em formato virtual devido à pandemia de
          COVID-19, através da participação com stand virtual dentro da "sala de
          exposições" da conferência (Exhibit Hall), acessível a todos os
          participantes e onde foi possível disponibilizar informação sobre o
          projeto de I&DT e resultados, assim como sobre a empresa, através de
          vídeos, apresentações e documentos. Além disso, reuniões 1:1 com
          visitantes do stand permitiram promover e divulgar de forma direta os
          resultados do projeto iPET.
        </p>

        <Carousel>
          <img className="img3" src={snmmi} alt="" />
          <img className="imagemsec" src={virtual} alt="" />
          <img className="imagemsec" src={stand} alt="" />
        </Carousel>

        <h2 className="ptop">Encontro anual EANM 2020</h2>
        <p>
          O 33º encontro anual da Associação Europeia de Medicina Nuclear (
          <a href="https://www.eanm.org/congresses-events/past-congresses/congresses-overview/">
            EANM
          </a>
          ) decorreu entre 22 e 30 de Outubro de 2020, numa edição excecional,
          pela primeira vez em formato virtual, face ao contexto da pandemia de
          COVID-19. A EANM é a maior organização dedicada à medicina nuclear da
          Europa com o objetivo de disseminar e discutir os últimos resultados
          na área, incluindo modalidades de imagem e assuntos relacionados,
          coordenando o câmbio de conhecimento sobre o diagnóstico, tratamento e
          prevenção de doenças através do uso de substâncias radioativas e
          propriedades de nuclídeos estáveis na medicina. Sediada em Viena de
          Áustria, representa mais de 9000 especialistas de 41 países da Europa.
          Os congressos anuais da EANM atraem mais de 6000 participantes em cada
          ano. A participação dos membros do consórcio neste evento teve o
          principal objetivo de divulgar e promover a tecnologia que tem vindo a
          ser desenvolvida no âmbito do projeto iPET, apresentando os resultados
          de I&D já alcançados e objetivos a atingir, expondo também os
          resultados de atividades de I&D anteriores que resultaram na
          construção do sistema easyPET.3D, ponto de partida para o atual
          projeto. Este evento permitiu aos participantes do consórcio
          apresentarem um total de 5 trabalhos, incluídos no programa
          científico: 2 apresentações orais (top rated oral presentations) e 3
          posters, assim como permitiu a participação da RI-TE com um stand
          virtual na exposição da indústria (industry exhibition), onde foi
          possível interagir diretamente com os visitantes e disponibilizar
          vários materiais de promoção e divulgação do projeto.
        </p>

        <Carousel>
          <img className="imagemsec" src={eanm} alt="" />
          <img className="imagemsec" src={eanm1} alt="" />
          <img className="imagemsec" src={eanm2} alt="" />
          <img className="imagemsec" src={eanm3} alt="" />
          <img className="imagemsec" src={eanm4} alt="" />
        </Carousel>

        <h2 className="ptop">Anúncio na plataforma ResearchGate</h2>
        <p>
          Durante os meses de março e abril de 2021, o projeto iPET foi
          divulgado através de uma campanha publicitária online, na plataforma
          ResearchGate, com anúncio a promover o projeto iPET a uma audiência
          específica de investigadores científicos e a redirecionar para página
          do projeto em inglês (
          <a href="https://www.ri-te.pt/ipet/">https://www.ri-te.pt/ipet/</a>),
          com apresentação e divulgação dos resultados do projeto.
          <a href="https://www.ri-te.pt/ipet/">
            <img className="img2" src={rg} alt="" />
          </a>
        </p>
      </div>
    </section>
  );
}

export default Eventos;
