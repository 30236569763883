import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "../Styles/header.css";
import transparente from "../Assets/transparente.png";

function Header() {
  return (
    <div className="centro">
      <img className="ipet" src={transparente} width="90%" alt="" />
      <Container className="centrar">
        <div className="introbtn">
          <div className="intro">
            <p>
              O <b>Projeto iPET</b> pretende desenvolver um tomógrafo PET
              inteligente para imagiologia pré-clínica, com um método de
              aquisição inovador que reduz a complexidade e custo destes
              sistemas e melhora a qualidade das imagens obtidas, detetando e
              varrendo de forma inteligente regiões de interesse específicas.
            </p>
          </div>
          <a href="#descricao">
            <Button id="btn-saiba-mais">SAIBA MAIS</Button>
          </a>

          <img className="ipet2" src={transparente} width="90%" alt="" />
        </div>
      </Container>
    </div>
  );
}

export default Header;
