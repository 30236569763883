import React from "react";
import "../Styles/table.css";
import Table from "react-bootstrap/Table";

function Descricao() {
  return (
    <section className="seccao1" id="descricao">
      <h1>Descrição do projeto</h1>
      <div className="texto">
        <p>
          O projeto iPET pretende desenvolver um tomógrafo PET inteligente para
          imagiologia pré-clínica, com um método de aquisição inovador que reduz
          a complexidade e custo destes sistemas e melhora a qualidade das
          imagens obtidas, detetando e varrendo de forma inteligente regiões de
          interesse específicas.
        </p>
      </div>
      <div className="centro2">
        <Table responsive>
          <tbody>
            <tr>
              <td className="negrito">Designação</td>
              <td>Projetos de I&DT Empresas em co-promoção</td>
            </tr>
            <tr>
              <td className="negrito">Código do projeto</td>
              <td>39880</td>
            </tr>
            <tr>
              <td className="negrito">Objetivo principal</td>
              <td>
                Reforçar a investigação, o desenvolvimento tecnológico e a
                inovação
              </td>
            </tr>
            <tr>
              <td className="negrito">Região de intervenção </td>
              <td>Centro</td>
            </tr>
            <tr>
              <td className="negrito">Entidades beneficiárias</td>
              <td>
                RI-TE Radiation Imaging Technologies, Lda (líder)<br></br>
                Universidade de Aveiro<br></br>
                Universidade de Coimbra Exatronic, Lda<br></br>
              </td>
            </tr>
            <tr>
              <td className="negrito">Data de aprovação</td>
              <td>06/05/2019</td>
            </tr>
            <tr>
              <td className="negrito">Data de início</td>
              <td>11/09/2019</td>
            </tr>
            <tr>
              <td className="negrito">Data de conclusão</td>
              <td>10/03/2022 (prorrogada até 30/11/2022)</td>
            </tr>
            <tr>
              <td className="negrito">Investimento total elegível</td>
              <td>1.269.558,76€</td>
            </tr>
            <tr>
              <td className="negrito">Apoio financeiro da UE (FEDER)</td>
              <td>953.776,16€</td>
            </tr>
            <tr>
              <td className="negrito">Entidade financiadora</td>
              <td>CENTRO 2020 – Programa Operacional Regional do Centro</td>
            </tr>
            <tr>
              <td className="negrito">Organismo intermédio</td>
              <td>ANI - Agência Nacional de Inovação S.A.</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </section>
  );
}

export default Descricao;
