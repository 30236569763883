import React from "react";
import triangulo from "../Assets/triangulo.png";

function Resultados() {
  return (
    <section className="seccao3 relativa" id="resultados">
      <h1>Resultados</h1>
      <div className=" grelha">
        <div className="cartao">
          <span>
            Estudos experimentais e de simulação do sistema iPET e células
            unitárias
          </span>
        </div>
        <div className="cartao">
          <span>Definição de especificações e requisitos técnicos</span>
        </div>
        <div className="cartao">
          <span>Sistemas eletrónico e mecânico do tomógrafo iPET</span>
        </div>
        <div className="cartao">
          <span>
            Algoritmos de aquisição e reconstrução de imagem inteligentes
          </span>
        </div>

        <div className="cartao">
          <span>
            Firmware de controlo e software de aquisição de dados, visualização
            e pós-processamento de imagem (interface gráfica)
          </span>
        </div>
        <div className="cartao">
          <span>Construção de protótipos</span>
        </div>
        <div className="cartao">
          <span>Testes e ensaios laboratoriais e em ambiente pré-clínico</span>
        </div>
      </div>
      <img className="triangulo" src={triangulo} alt="" />
    </section>
  );
}

export default Resultados;
