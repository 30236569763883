import React from "react";

function Footer() {
  return (
    <div className="seccao4">
      <p className="padtop">RI-TE, Lda - Radiation Imaging Technologies</p>
      <p>Universidade de Aveiro Incubator</p>
      <p>PCI - Creative Science Park</p>
      <p>Via do Conhecimento, Edifício Central</p>
      <p className="padbot">3830-352 Ílhavo (Portugal)</p>
      <p>info@ri-te.pt</p>
      <p className="padbot padtop">All rights reserved © 2015-2022 RI-TE.</p>
    </div>
  );
}

export default Footer;
