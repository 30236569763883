import React from "react";
import "../Styles/pt2020.css";
import pt2020 from "../Assets/centro2020.png";

function PT2020() {
  return (
    <div>
      <img className="imgConsorcio2" src={pt2020} alt="PT2020 logo" />
    </div>
  );
}

export default PT2020;
