import React from "react";

function Sintese() {
  return (
    <section className="seccao2" id="sintese">
      <h1>Síntese</h1>
      <div className="texto">
        <p>
          A Tomografia por Emissão de Positrões (PET) é uma técnica de
          diagnóstico muito poderosa, amplamente usada para obter imagens
          funcionais de órgãos e tecidos, capaz de detetar doenças numa fase
          muito inicial. É a técnica de imagem mais usada na prática clínica e
          também em investigação em Oncologia, sendo também muito usada para
          estudar funções cerebrais e cardíacas.
        </p>
        <p>
          A PET provou ser particularmente útil no estadiamento da doença e
          monitorização da sua evolução e resposta à terapia. Os sistemas PET
          pré-clínicos são usados para avaliar novos fármacos e terapias, assim
          como biomarcadores para diagnóstico por imagem, em modelos animais de
          doença (ratos e ratinhos), antes de ensaios clínicos{" "}
        </p>
        <p>
          Neste contexto, a PET pré-clínica é uma importante ferramenta na
          investigação médica, com o potencial de acelerar o processo de
          descoberta e desenvolvimento de novos fármacos. No entanto, a alta
          complexidade e custo de implementação da tecnologia e restrições
          orçamentais das instituições e projetos de investigação na área da
          saúde, limitam a sua adoção e uso.
        </p>
        <p>
          A tecnologia iPET permite o desenvolvimento de novos sistemas PET
          pré-clínicos, de alta resolução e baixo custo, com um método único e
          patenteado de aquisição de imagem inteligente. O sistema iPET irá
          permitir a democratização do acesso à tecnologia PET no ambiente
          pré-clínico.
        </p>
        <p>
          Esta nova tecnologia, desenvolvida em parceria com a Universidade de
          Aveiro, assegura uma excelente performance de imagem PET a um preço
          muito inferior: cerca de 20% dos sistemas concorrentes. Com base em
          atividades de I&D prévias que provaram com sucesso o conceito da
          tecnologia, o projeto iPET propõe-se desenvolver, assemblar e testar
          um sistema PET pré-clínico para investigação biomédica.
        </p>
      </div>
    </section>
  );
}

export default Sintese;
