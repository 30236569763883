import React from "react";
import rite from "../Assets/RITE.png";
import exatronic from "../Assets/Exatronic.png";
import ua from "../Assets/UA.png";
import uc from "../Assets/UC.png";

function Consorcio() {
  return (
    <div className="consorcio" id="consorcio">
      <h1>Consórcio</h1>
      <div className=" grelha">
        <div className="cartao">
          <img className="imgConsorcio" src={rite} alt="ri-te logo" />
          <span>
            Startup dedicada à investigação e desenvolvimento no domínio da
            instrumentação nuclear para deteção e imagiologia de radiação.
          </span>
        </div>

        <div className="cartao">
          <img className="imgConsorcio" src={ua} alt="ua logo" />
          <span>
            Departamentos de Física (Lab. de Deteção de Radiação e Imagiologia
            Médica), Eng. Mecânica e Comunicação e Arte.
          </span>
        </div>

        <div className="cartao">
          <img className="imgConsorcio" src={uc} alt="uc logo" />
          <span>
            Grupo de modelação em Oncobiologia do Instituto de Investigação
            Clínica e Biomédica de Coimbra (iCBR).
          </span>
        </div>

        <div className="cartao">
          <img className="imgConsorcio" src={exatronic} alt="exatronic logo" />
          <span>
            Empresa especializada em inovação e investigação, engenharia e
            produção de eletrónica.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Consorcio;
